const Config = {
  adjust: {
    appToken: '7fmrng3ryfwg',
    environment: 'production'
  },
  apiRegistrationToken:
    '$2y$10$sR5J2rRZIxoJvWtQ4SXlFe2DpZajuYh0YSHLuAZmP0MO9Tk9auT0e',
  apiHostGateway:
    'https://api.finalcad.cloud',
  apiHostGatewayGlobal:
    'https://api.global.finalcad.cloud',
  grpcGateway: 'https://realtime-service-pusher.ap.finalcad.cloud',
  finalcadLiveLandingPage:
  'https://app.ap.finalcad.com',
  finalcadAgreementLink:
  'https://www.finalcad.com/general-terms-of-use',
  authLoginPath: '/login',
  googleApiKey: 'AIzaSyA4S7pXATD7Yh44C3XxiEaHaEEmTEcwjyQ',
  pdfTronLicenseKey: 'Finalcad SARL (finalcad.com):OEM:Finalcad One::B+:AMS(20230329):40A584BD04B7A80AF360B13AC9A2537860612FDDB728ED4BA514ACE6484418D65AAAB6F5C7',
  firebaseConfig: {
    apiKey: 'AIzaSyAEmhLO21A_KSfPNbh6QMQMpfco7TOkluQ',
    authDomain: 'finalcad-one-33577.firebaseapp.com',
    databaseURL: 'https://finalcad-one-33577.firebaseio.com',
    projectId: 'finalcad-one-33577',
    storageBucket: 'finalcad-one-33577.appspot.com',
    messagingSenderId: '929576455949',
    appId: '1:929576455949:web:e6c59f487055f2af236a44',
    measurementId: 'G-PJ815XCP3E',
  },
  airbrakeId: '893f0d22c8591da0454c429127b16d6c',
  airbrakeProjectKey: '279967',
  intercomAppID: 'mqmverre',
  stripePublishableKey: 'pk_live_51ILQ3mLfL2ThMUp8WHjPePbAgNrh6PFEnK7viRsWQjneswFRBnYPn1FgG1wI0B534ksvzGjnvnlAsIRlCCKOUNTn00pzflD6Nt',
  stripeCheckoutKey: 'pk_live_51ILQ3mLfL2ThMUp8WHjPePbAgNrh6PFEnK7viRsWQjneswFRBnYPn1FgG1wI0B534ksvzGjnvnlAsIRlCCKOUNTn00pzflD6Nt',
  okta: {
    oidc: {
      clientId: '0oadputk99dgi2bb5416',
      issuer: 'https://id.finalcad.com/oauth2/ausdmypaqs8JpdGs0416',
      redirectUri: '$\{window.location.origin\}/login/callback',
      scopes: ['openid', 'profile', 'email'],
    },
    domain: 'https://id.finalcad.com',
    idps: [
      { type: 'GOOGLE', id: '0oadxuf6hBOLgUBJD416' },
      { type: 'MICROSOFT', id: '0oadxtn57gwLIIOJk416' },
      { type: 'APPLE', id: '0oadqer9n9xssaaXZ416' },
    ],
  },
  sentry: {
    environment: 'production',
    dsn: 'https://9d76d2c8f44f4443a59a458eca6a1dca@o1003935.ingest.sentry.io/5973717',
  },
  zendeskWebviewId: '137626b1-ec63-40e1-b26f-1a54470e247e',
  customerIo: {
    siteId: '28a91593de24a5194848',
    apiKey: '89431674b3de901f638e',
  },
  gleap: {
    appToken: 'XEeED8r4vn9THhSQBfIPlUyUnmBhVZhL',
  },
  googleMapsApiKey: 'AIzaSyAEmhLO21A_KSfPNbh6QMQMpfco7TOkluQ',
};
module.exports = Config;

